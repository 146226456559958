<template>
  <div class="change_bg">

    <Navbar></Navbar>
    <section>
      <div class="container">
        <div class="manage_link">
          <div class="m_link1">
            <router-link to="/">{{$store.state.language.lang[1]}}</router-link>
          </div>
          <div>
            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M0.106862 0.191111C0.277469 -0.0260253 0.591796 -0.0637446 0.808932 0.106862L7.80893 5.60686C7.92958 5.70165 8.00002 5.84659 8.00002 6.00002C8.00002 6.15345 7.92958 6.29839 7.80893 6.39318L0.808932 11.8932C0.591796 12.0638 0.277469 12.0261 0.106862 11.8089C-0.0637446 11.5918 -0.0260253 11.2775 0.191111 11.1069L6.69073 6.00002L0.191111 0.89318C-0.0260253 0.722574 -0.0637446 0.408246 0.106862 0.191111Z"
                    fill="#89899F"/>
            </svg>
          </div>
          <div class="m_link2">
            <router-link to="/">
              {{$store.state.language.lang[182]}}
            </router-link>
          </div>
        </div>
        <div class="row">
          <!-- <div class="col-xl-3 col-12">
            <div class="m_sidebar">
              <div class="m_sidebar_title">
                {{$store.state.language.lang[3]}}
              </div>
              <div class="m_sidebar_link">
                <router-link to="/news">
                  {{$store.state.language.lang[69]}}</router-link>
              </div>

              <div class="m_sidebar_link">
                <router-link to="/press_secretary">
                  {{$store.state.language.lang[71]}}</router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/press_releases">
                  {{$store.state.language.lang[72]}}</router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/tenders">
                  {{$store.state.language.lang[74]}}</router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/media">
                  {{$store.state.language.lang[70]}}</router-link>
              </div>
            </div>
          </div> -->

          <div class="col-xl-12 col-12">
            <div class="m_body_title">
              <h2>{{$store.state.language.lang[182]}}</h2>
            </div>
            <MediaSideNews :name="$store.state.language.lang[74]"></MediaSideNews>
            <form @submit.prevent="getSearch">
              <div>
                <div class="dec_input_group">
                  <div class="search_inputs">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M9.09091 1.81818C5.07429 1.81818 1.81818 5.07429 1.81818 9.09091C1.81818 13.1075 5.07429 16.3636 9.09091 16.3636C13.1075 16.3636 16.3636 13.1075 16.3636 9.09091C16.3636 5.07429 13.1075 1.81818 9.09091 1.81818ZM0 9.09091C0 4.07014 4.07014 0 9.09091 0C14.1117 0 18.1818 4.07014 18.1818 9.09091C18.1818 11.2739 17.4124 13.2771 16.1299 14.8443L19.7337 18.4481C20.0888 18.8031 20.0888 19.3787 19.7337 19.7337C19.3787 20.0888 18.8031 20.0888 18.4481 19.7337L14.8443 16.1299C13.2771 17.4124 11.2739 18.1818 9.09091 18.1818C4.07014 18.1818 0 14.1117 0 9.09091Z"
                            fill="#01011C"/>
                    </svg>
                    <input type="text"  v-model="searchs" :placeholder="$store.state.language.lang[65]">
                  </div>
                  <div class="buttons_search">
                    <button>Search</button>
                  </div>
                </div>
              </div>

            </form>
            <div class="dec_text">
              {{ $store.state.language.lang[66] }}: {{$store.state.docs4.total}}
            </div>
            <div class="dec_card" v-for="(item,index) in $store.state.docs4.documents" :key="index">
              <div class="dec_card_main">
                <div class="loga_dec">

                  <svg width="54" height="72" viewBox="0 0 54 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.4229 50.7744H15.9082C16.8574 50.7744 17.5547 50.9561 18 51.3193C18.4512 51.6826 18.6768 52.2363 18.6768 52.9805C18.6768 53.5371 18.5566 53.9854 18.3164 54.3252C18.0762 54.665 17.7246 54.9141 17.2617 55.0723C16.7988 55.2246 16.2363 55.3008 15.5742 55.3008H14.4229V50.7744Z" fill="#3C3C58"/>
                    <path d="M31.1221 52.7871C31.4561 53.4668 31.623 54.334 31.623 55.3887C31.623 56.9531 31.2393 58.1309 30.4717 58.9219C29.7041 59.7129 28.5439 60.1084 26.9912 60.1084H25.4971V50.7744H27.2988C28.2363 50.7744 29.0244 50.9414 29.6631 51.2754C30.3076 51.6035 30.7939 52.1074 31.1221 52.7871Z" fill="#3C3C58"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 4.5C0 2.01472 2.01472 0 4.5 0H31.5V14.625C31.5 17.7316 34.0184 20.25 37.125 20.25H54V67.5C54 69.9853 51.9853 72 49.5 72H4.5C2.01472 72 0 69.9853 0 67.5V4.5ZM19.6787 50.0449C18.917 49.3652 17.707 49.0254 16.0488 49.0254H12.3135V61.875H14.4229V57.0586H15.7939C16.7314 57.0586 17.5195 56.9473 18.1582 56.7246C18.8027 56.502 19.3213 56.2002 19.7139 55.8193C20.1064 55.4326 20.3877 54.9902 20.5576 54.4922C20.7334 53.9883 20.8213 53.458 20.8213 52.9014C20.8213 51.6768 20.4404 50.7246 19.6787 50.0449ZM33.0117 58.957C33.5508 57.9844 33.8203 56.7744 33.8203 55.3271C33.8203 53.9502 33.5566 52.7959 33.0293 51.8643C32.502 50.9268 31.7549 50.2207 30.7881 49.7461C29.8213 49.2656 28.6729 49.0254 27.3428 49.0254H23.3877V61.875H26.9561C28.4033 61.875 29.6396 61.6318 30.665 61.1455C31.6904 60.6533 32.4727 59.9238 33.0117 58.957ZM36.5889 61.875H38.6807V56.5752H43.5146V54.8086H38.6807V50.8008H43.8486V49.0254H36.5889V61.875Z" fill="#3C3C58"/>
                    <path d="M33.75 14.625V0.337929C34.2144 0.528813 34.6473 0.797595 35.0287 1.13666L52.4896 16.6575C52.92 17.04 53.2678 17.4967 53.5207 18H37.125C35.261 18 33.75 16.489 33.75 14.625Z" fill="#3C3C58"/>
                  </svg>

                </div>
                <div class="title_dec">
                  <div class="dec_title">{{item.title}}</div>
                  <div class="dec_num">№ {{item.document_number}} {{new Date(item.published_date).toLocaleDateString()}}
                    <a :href="item.link" target="_blank" class="tenders_link">Ishtirok etish</a>
                  </div>
                </div>

              </div>
              <div class="download">
                <a :href="item.file">
                  <div class="link_docs">
                    <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M21.084 13.667C21.084 13.2528 20.7482 12.917 20.334 12.917C19.9198 12.917 19.584 13.2528 19.584 13.667H21.084ZM2.41797 13.667C2.41797 13.2528 2.08218 12.917 1.66797 12.917C1.25376 12.917 0.917969 13.2528 0.917969 13.667H2.41797ZM17.2793 9.80733C17.5722 9.51444 17.5722 9.03957 17.2793 8.74667C16.9864 8.45378 16.5115 8.45378 16.2186 8.74667L17.2793 9.80733ZM11.036 14.99L10.5056 15.5203C10.6463 15.661 10.8371 15.74 11.036 15.74C11.2349 15.74 11.4256 15.661 11.5663 15.5203L11.036 14.99ZM5.8533 8.74667C5.56041 8.45378 5.08553 8.45378 4.79264 8.74667C4.49975 9.03957 4.49975 9.51444 4.79264 9.80733L5.8533 8.74667ZM11.786 1.677C11.786 1.26279 11.4502 0.927002 11.036 0.927002C10.6218 0.927002 10.286 1.26279 10.286 1.677H11.786ZM10.286 14.973C10.286 15.3872 10.6218 15.723 11.036 15.723C11.4502 15.723 11.786 15.3872 11.786 14.973H10.286ZM19.584 13.667V16.714H21.084V13.667H19.584ZM19.584 16.714C19.584 17.9568 18.5768 18.964 17.334 18.964V20.464C19.4052 20.464 21.084 18.7852 21.084 16.714H19.584ZM17.334 18.964H4.66797V20.464H17.334V18.964ZM4.66797 18.964C3.42518 18.964 2.41797 17.9568 2.41797 16.714H0.917969C0.917969 18.7852 2.59675 20.464 4.66797 20.464V18.964ZM2.41797 16.714V13.667H0.917969V16.714H2.41797ZM16.2186 8.74667L10.5056 14.4597L11.5663 15.5203L17.2793 9.80733L16.2186 8.74667ZM11.5663 14.4597L5.8533 8.74667L4.79264 9.80733L10.5056 15.5203L11.5663 14.4597ZM10.286 1.677V14.973H11.786V1.677H10.286Z" fill="#00B57F"/>
                    </svg>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from "../Header/Navbar";
import Footer from "../Header/Footer";
import MediaSideNews from "../MediaSideNews";

export default {
  components: {
    MediaSideNews,
    Footer,
    Navbar
  },
  data() {
    return {
      isActive_m: false,
      searchs:''
    }
  },
  mounted() {
    this.$store.dispatch('docs4')
  },
  methods: {
    getSearch(){
      this.$store.state.docsn1=[]
      let a=""
      if(isNaN(this.searchs)==false){
        a={
          "name":`number=${this.searchs}`
        }
      }

      if(isNaN(this.searchs)==true){
        a={
          "name": `doc_name=${this.searchs}`
        }
      }
      if(this.searchs===''){
        this.$store.dispatch('docs4')
      }

      this.$store.dispatch('docs1',a)
    }
  }
}
</script>
